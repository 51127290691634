import React from 'react'
import { Wrapper } from './style'

const ToggleMenu = props =>
    <Wrapper {...props}>
        <span />
        <span />
        <span />
    </Wrapper>

export default ToggleMenu
